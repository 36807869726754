const dataHelper = {
    /**
     * 转义字符串 转换为可展示的json 字符串
     * @param jsonString
     * @returns {string}
     */
    stringToJsonString(jsonString)
    {
        return this.objectToString(this.jsonStringToObject(jsonString));
    },
    /**
     * 对象转字符串
     * @param jsonString
     * @returns {string}
     */
    objectToString(objectData)
    {
        return JSON.stringify(objectData);
    },
    /**
     * 字符串转json对象
     * @param jsonString
     * @returns {string}
     */
    jsonStringToObject(jsonString)
    {
        let newData = this.isEmptyString(jsonString) || this.isEmptyObject(jsonString)?{}:JSON.parse(jsonString);
        if(this.isString(newData)){// 当处理完还是字符串时，自动再转一次
            newData = JSON.parse(newData);
        }
        return newData;
    },
    /**
     * 判断是否为对象-字符串
     * @param objectString
     * @returns {boolean}
     */
    isObjectString(objectString){
        return Object.prototype.toString.call(objectString) === "[object String]";
    },

    /**
     * 判断对象是否为空
     * @param objectData
     * @returns {boolean}
     */
    isEmptyObject(objectData){
        return this.objectToString(objectData) === "{}";
    },
    /**
     * 是否是base64
     * @param str
     * @returns {boolean}
     */
    isBase64(base64String){
        if(base64String === '' || base64String.trim() === ''){
            return false;
        }
        try{
            return btoa(atob(base64String)) == base64String;
        }catch(err){
            return false;
        }
    },

    /**
     * 判断数组是否为空
     * @param arrayData
     * @returns {boolean}
     */
    isEmptyArray(arrayData){
        return arrayData == undefined || arrayData.length <= 0;
    },
    /**
     * 判断字符串是否为空
     * @param stringData
     * @returns {boolean}
     */
    isEmptyString(stringData){
        return  stringData == '' || stringData == null;
    },
    /**
     * 判断对象是否包含某个属性
     * @param objectData
     * @returns {boolean}
     */
    isHasObject(objectData,propName){
        return objectData.hasOwnProperty(propName);
    },
    /**
     * 验证数据 是数字：返回true；不是数字：返回false
     *
     * isNaN(val)不能判断空串或一个空格
     * 如果是一个空串、空格或null，而isNaN是做为数字0进行处理的，
     * 而parseInt与parseFloat是返回一个错误消息，这个isNaN检查不严密而导致的。
     **/
    isNumber(val) {
        return parseFloat(val).toString() == "NaN"?false:true;
    },
    /**
     * 判断是否手机号
     * @param str
     * @returns {boolean}
     */
    isPhoneNumber(str) {
        const regExp = /^1[3456789]\d{9}$/;
        return regExp.test(str);
    },
    /**
     * 判断是否邮箱号
     * @param str
     * @returns {boolean}
     */
    isEmail(str) {
        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        return emailRegex.test(str);
    },
    /**
     * 字符串首字母大写
     * @param str
     * @returns {string}
     */
    titleCase(str) {
        let newStr = str.slice(0,1).toUpperCase() +str.slice(1).toLowerCase();
        return newStr;
    },
    /**
     * 是否是html标签
     * @param str
     * @returns {boolean}
     */
    isHtml(str) {
        var htmlRegex = /<("[^"]*"|'[^']*'|[^'"])*>/; // HTML标记的正则表达式
        return htmlRegex.test(str);
    },

    /**
     * 获取内容中的img标签
     * @param htmlString
     * @returns {RegExpMatchArray}
     */
    getAllImgForHtmlMessage(htmlString=''){
        let imgReg = /<img.*?(?:>|\/>)/gi //匹配图片中的img标签
        return htmlString.match(imgReg)  //筛选出所有的img
    },
    /**
     * 获取内容中的a标签
     * @param htmlString
     * @returns {RegExpMatchArray}
     */
    getAllKeyForHtmlMessage(htmlString='',htmlKey='samp',className=''){
        let imgReg = '';
        if(className==''){
            imgReg = new RegExp(`<${htmlKey}.*?(?:\/${htmlKey}>|<\/${htmlKey}>)`, "gi");
        }else{
            imgReg = new RegExp(`<${htmlKey} class="${className}" .*?(?:\/${htmlKey}>|<\/${htmlKey}>)`, "gi");
        }
        console.info('imgReg:',imgReg)
        return htmlString.match(imgReg)  //筛选出所有的a
    },
    /**
     * 获取html 标签中某个属性参数
     * @param htmlString
     * @param attrName 属性名称
     * @returns {string}
     */
    getAttrByHtml(htmlString='',attrName='src'){
        //let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i // 匹配图片中的src
        let reg = new RegExp(` ${attrName}=[\'\"]?([^\'\"]*)[\'\"]?`, "i");
        let srcArray = htmlString.match(reg);
        return srcArray[1];
    },
    /**
     * 去除空格
     * @param textString
     * @returns {string}
     */
    stringForRemoveSpaces(textString=''){
        textString = textString.replace(/&nbsp;/gi, "");
        return textString.replace(/\s/g, "");
    },
    /**
     * 去除字符串中的html标签
     * @param textString
     * @returns {string}
     */
    stringForRemoveHtmlTab(textString=''){
        return textString.replace(/<[^>]+>/g, '');
    },

    /**
     * 单位转换（输入为单位为 b）
     * @param val
     * @param isCeil 是否取整
     * @returns {string}
     */
    bNumForUnitConversion(val,isCeil=false){
        if(val === 0) return "0 B"
        let k = 1024;
        let sizes = ['B','KB','MB','GB','PB','TB','EB','ZB','YB'],
            i = Math.floor(Math.log(val) / Math.log(k));
        let valData = (val / Math.pow(k,i));
        valData = isCeil?Math.ceil(valData):valData.toPrecision(3);
        return valData + " " + sizes[i]
    },
    /**
     * 字符串缩略处理
     * @param string
     * @param maximumLength
     * @param suffixStringLenght
     * @returns {string}
     */
    stringToAbbreviate(string='',maximumLength=30,suffixStringLenght=7){
        if(string.length>maximumLength){
            let prefixStringLenght = maximumLength - suffixStringLenght - 3;
            let prefixString = string.substring(0,prefixStringLenght);
            let suffixString = string.substring(string.length-suffixStringLenght);
            string = prefixString+'...'+suffixString;
        }
        return string;
    },
    /**
     * 判断是否为对象
     * @param obejectData
     * @returns {boolean}
     */
    isObject(obejectData){
        return Object.prototype.toString.call(obejectData) === '[object Object]';
    },
    /**
     * 判断是否为字符串
     * @param stringData
     * @returns {boolean}
     */
    isString(stringData){
        return typeof(stringData)=='string';
    },
    /**
     * 获取当前日期，示例：2023-06-23
     * @returns {string}
     */
    getNowDateDay(){
        let day = new Date()
        let time = day.getFullYear() + "-" +
            (day.getMonth() + 1) + "-" +
            day.getDate();
        return time;
    },
    /**
     * 获取当前时间戳，单位s
     * @returns {string}
     */
    getNowTimeStamp(){
        let tTime = new Date().valueOf();//单位为毫秒
        return parseInt(tTime/1000);//当前时间戳(单位为s,跟服务端保持一致)
    },
};
export default  dataHelper;