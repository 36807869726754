import {createApp, nextTick} from 'vue'
import router from '@app/router'
import store from '@app/store'

import App from '@app/app.vue'
// 引入公共包
import MakeitAdminPro from 'summer-admin-pro'
import 'summer-admin-pro/dist/miitvip.min.css'


// icon
import * as Icons from '@ant-design/icons-vue'
// xss 攻击防御
import VueDOMPurifyHTML from 'vue-dompurify-html'



const app = createApp(App)
app.use(router)
app.use(store)
app.use(VueDOMPurifyHTML)

app.use(MakeitAdminPro)
app.mount('#app');

nextTick(() => {
   // console.info('22222222222',Icons)
    // 配置全局对象
    app.config.globalProperties.$icons = Icons
    // Antd 注入全部图标（这样注入之后，就可以全局直接使用 icon 组件，不需要每个页面去引入了,调用示例：<component :is="$icons['BugOutlined']" />）
    for (const key in Icons) { app.component(key, Icons[key]) }
})
