// import Vue from 'vue'
import Vuex, {useStore} from 'vuex'
import permission from './modules/permission'
import base from './modules/base'
import converse from './modules/converse'
import getters from './getters'


const store = new Vuex.Store({
  modules: {
    permission,
    base,
    converse
  },
  getters
})

export default store

// import store from '../store';
// store.dispatch('SetValidity', res.data);
// store.getters.isValidity;
