import { reactive } from 'vue'
// 覆盖插件原始定义的url
const systemApi = reactive({
    login: 'base/login',
    logout: 'base/logout',
    register: 'base/register',
    updateLoginDuration: 'base/update-login-duration',
    // refresh: 'oauth/refresh',
    // authorize: 'oauth/{socialite}/login',// 其他平台账号登录 OAuth2
    captcha: {
        init: 'base/captcha-init',
        verify: 'base/captcha-verify'
    },
    // email: {
    //     active: 'email/active/{token}',
    //     resend: 'email/active/resend'
    // },
    validator: {
        name: 'base/validator-name',
        email: 'base/validator-email',
        user: 'base/validator-user'
    },
    password: {
        reset: 'base/password-reset',
        captcha: 'base/password-captcha-send',
        check: 'base/password-captcha-checkout'
    },
    languages: {
        data: '',
    }
    // languages: {
    //     data: 'languages',
    //     batchCreate: 'languages/batch',
    //     update: 'languages/{id}',
    //     check: 'languages/checkout',
    //     category: {
    //         data: 'languages/categories',
    //         update: 'languages/categories/{id}',
    //         check: 'languages/categories/checkout',
    //         default: 'languages/categories/default/{id}'
    //     }
    // },
    // menus: {
    //     data: 'menus',
    //     update: 'menus/{id}',
    //     delete: 'menus/{id}'
    // },
    // apps: {
    //     data: 'apps',
    //     create: 'apps',
    //     update: 'apps/{id}',
    //     delete: 'apps/{id}',
    //     images: 'images'
    // }
})
export default systemApi