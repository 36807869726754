import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// const Dashboard = () => import('@views/dashboard.vue')
// const Start = () => import('@views/start.vue')
// const Theme = () => import('@views/theme.vue')
const PassportLogin = () => import('@views/passport/login.vue')
const PassportRegister = () => import('@views/passport/register.vue')
const PassportForget = () => import('@views/passport/forget.vue')
const PassportUpdate = () => import('@views/passport/update.vue')
// const SocialiteLogin = () => import('@views/socialite/login.vue')
const Letter = () => import('@views/letter/index.vue')

const menuRoutes: Array<RouteRecordRaw> = [{
    path: '/',
    meta: { title: '首页' },
    component: Letter
}]

const letterRoutes: Array<RouteRecordRaw> = [{
    path: '/letter',
    name: 'letter',
    meta: { title: '网云' },
    component: Letter
}]

const passportRoutes: Array<RouteRecordRaw> = [{
    path: '/login',
    name: 'single-login',
    meta: { title: '登录' },
    component: PassportLogin
}, {
    path: '/register',
    name: 'single-register',
    meta: { title: '注册' },
    component: PassportRegister
}, {
    path: '/forget',
    name: 'single-forget',
    meta: { title: '找回密码' },
    component: PassportForget
}, {
    path: '/passport/update',
    name: 'single-update',
    meta: { title: '重置密码' },
    component: PassportUpdate
}]

const routes: Array<RouteRecordRaw> = [
    ...menuRoutes,
    ...passportRoutes,
    ...letterRoutes
]

const router = createRouter({
    history: createWebHistory(),
    routes
})


export default router