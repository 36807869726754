<template>
  <router-view></router-view>
</template>

<script setup>
    import { getCurrentInstance, toRefs } from 'vue'
    import { nextTick } from 'vue'

    nextTick().then(() => {
        const _loading = document.getElementById('mi-loading-before-init')
        if (_loading) _loading.remove()
    })

    // 覆盖插件原始定义的全局变量
    const { appContext: {config: {globalProperties: vm}} } = getCurrentInstance()
    import customTools from '@app/utils/tools'
    // 覆盖插件的公共变量
    import customGlobal from '@app/utils/global'
    vm.$g = customTools.updateByReactiveData(vm.$g,customGlobal);

    // 覆盖插件原始定义的url
    import systemApi from '@app/router/system-api'
    vm.api = customTools.updateByReactiveData(vm.api,systemApi);

    // 初始化 store (针对刷新页面的数据重新初始化)
    import systemHelper from "@app/helpers/system-helper";
    systemHelper.initStoreData();
</script>