import CryptoJS from 'crypto-js';
import md5 from "js-md5";
import dataHelper from './data-helper';


/**
 * 加密方式
 * @type {{AesCryptoConfig: {AesIv: string, AesKey: string}, cryptoResponseName: string}}
 */
const CRYPTO = {
    AesCryptoConfig: {
        AesIv: '1234567890abcdef1234567890abcdef',// AES 加密算法的iv偏移
        AesKey: 'app-aes' // AES 加密算法的盐值
    },
};

function parameterHelper(userToken) {
 // let userToken = tempToken ? tempToken : localStorage.getItem(setting.userToken);
  let keyStr = md5(userToken + CRYPTO.AesCryptoConfig.AesKey);   //32位的密钥，和下面的密钥要相同
  const token = CryptoJS.enc.Utf8.parse(keyStr);  //md5后保持32位长度
  const iv = CryptoJS.enc.Utf8.parse(CRYPTO.AesCryptoConfig.AesIv);//setting.AesCryptoConfig.AesIv 也用过跟key保持一致的长度
  const opinion = {iv, mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7};//支持php7.2 openssl_encrypt 加密方式
  return {opinion, key: token};
}

export default {
    /**
     * 加密输入为对象类型
     * @param word
     * @param token
     * @returns {*}
     */
    objectToEncrypt(objectData={},token='vue-php'){
        let result = '';
        if(dataHelper.isObject(objectData)){
            let word = dataHelper.objectToString(objectData);
            result = this.encrypt(word,token);
        }
        return result;
    },
    /**
     * 解密结果为对象类型
     * @param word
     * @param token
     * @returns {*}
     */
    objectToDecrypt(word='',token='vue-php'){
        let resultData = {};
        word = word.replace(/\\/g, '');//去除字符串中有可能多余的反斜杠
        let isBase64 = dataHelper.isBase64(word);
        if(isBase64){
            let returnData = this.decrypt(word,token);
            let isObjectString = dataHelper.isObjectString(returnData);
            resultData = isObjectString?dataHelper.jsonStringToObject(returnData):{};
        }
        return resultData;
    },
  /**
   *  加密 （支持php7.2 openssl_encrypt 加密方式 ）
   * @param word 需要加密的值
   * @returns {any}
   */
  encrypt(word,token) {
    const {opinion, key} = parameterHelper(token);
    const encrypted = CryptoJS.AES.encrypt(word, key, opinion);
    return encrypted.toString();
  },
  /**
   *  解密 （支持php7.2 openssl_encrypt 加密方式 ）
   * @param word 需要解密的值
   * @param token 自定义加密密钥
   * @returns {any}
   */
  decrypt(word, token) {
    const {opinion, key} = parameterHelper(token);
    let decrypted = CryptoJS.AES.decrypt(word, key, opinion);//word 默认本身就是base64加密字符串
    decrypted = CryptoJS.enc.Utf8.stringify(decrypted).toString();
    return decrypted === ""?false:decrypted;//json字符串转对象
  }
};
