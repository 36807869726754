import cryptoHelper from '@app/helpers/crypto-helper';
import dataHelper from '@app/helpers/data-helper';
import systemHelper from '@app/helpers/system-helper';
import defaultHelper from '@app/helpers/data-default-helper';
let defaultMap = defaultHelper.dashboard();

// Vuex分成五个部分：
// 1.State：单一状态树  //可以理解成定义属性
// 2.Getters：状态获取    //可以理解成取值，也就是get方法
// 3.Mutations：触发同步事件    //同步的赋值
// 4.Actions：提交mutation，可以包含异步操作    //异步的赋值（但是其实就是在Mutations的基础上包装了一层）
// 5.Module：将vuex进行分模块
const base = {
	 state: {
        isPhoneVisitor:false,
	    nowUser: {},//{"username":"admin","id":1,"avatar":"http://user-converse-quick.com/data/upload/avatar/20240118/20240118111620_642.png","nickname":"信息问题"}
	    db: {},//{link:'127.0.0.1:3306', user:'zhangsan1', pass:'123456', name:'admin_oss'}
        validityKey: '',//base64
        validityEnd: '',// 授权最后有效日期 2023-06-23
        isValidity: false,// 当前是否有效授权 true
	},
	mutations:{
        SET_IS_PHONE_VISITOR: (state, data) => {
            //动态数据库信息
            state.isPhoneVisitor = data;
        },
	    SET_NOW_USER: (state, userData) => {
	    	//动态数据库信息
            let userObject = dataHelper.isObject(userData)?userData:dataHelper.jsonStringToObject(userData);
            if(!dataHelper.isEmptyObject(userObject)){
                //动态数据库信息
                state.nowUser = userObject;
            }
	    },
	    SET_DB: (state, dbData) => {
	    	//动态数据库信息
            let token = dbData.saltToken;
            let decryptObject = cryptoHelper.objectToDecrypt(dbData.dbData,token);//{empower:"2022-06-22",end:"2022-08-22",empower_user:1}
            if(!dataHelper.isEmptyObject(decryptObject)){
                //动态数据库信息
                state.db.link = decryptObject.link;
                state.db.pass = decryptObject.pass;
                state.db.name = decryptObject.name;
                state.db.user = decryptObject.user;
            }
	    },
        SET_VALIDITY: (state, validityKey) => {
            state.validityKey = validityKey;
            let token = defaultMap.saltToken;
            let decryptObject = cryptoHelper.objectToDecrypt(validityKey,token);//{empower:"2022-06-22",end:"2022-08-22",empower_user:1}
            if(!dataHelper.isEmptyObject(decryptObject)){
                //动态数据库信息
                state.validityEnd = decryptObject.end;
                state.isValidity = systemHelper.checkValidityKeyEndTime(decryptObject.end);
            }
        }
	},
	actions: {
        SetIsPhoneVisitor({ commit }, data) {
            commit('SET_IS_PHONE_VISITOR', data);
        },
        SetDbData({ commit }, data) {
            commit('SET_DB', data);
        },
        SetValidity({ commit }, validityKey) {
            commit('SET_VALIDITY', validityKey);
        },
        SetNowUser({ commit }, userData) {
            commit('SET_NOW_USER', userData);
        },
	},

}
//store.dispatch('SetValidity', validityKey,token).then(() => {
//     store.getters.validityKey,
//     store.getters.validityEnd,// 授权最后有效日期
//     store.getters.isValidity,// 当前是否有效授权
//  })
export default base;
