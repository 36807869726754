import  routes  from '@app/router'

function hasPermission(roleRouers)
{
	let routerItems = [];

	roleRouers.forEach((val)=>{

		let item = getAsyncRouter(val);
		if(item){
			routerItems.push(item);
		}
	})
	return routerItems;
}


function getAsyncRouter(roleRouter)
{
	if(roleRouter['path'] && !roleRouter.children){

		var arr = roleRouter['path'].split('/');
		var index = arr[arr.length-1];
		let routerItems = {
	            path: roleRouter['path'],
	            component: getViews(roleRouter['path']),
	            meta: { title:roleRouter['name'],is_menu:roleRouter['is_menu']},
	            index: index,
	            name: roleRouter['name'],
		};
		return routerItems;
	}
	return false;

}
// 获取path对应的展示页面
function getViews(path) {
	if(path.substr(0,1)=='/')  path = path.substr(1);
    return resolve => {
        require.ensure([], () => {
          resolve(require(`../../`+path+`.vue`))
        })
    }
}

//添加默认的路由
function addDefaultRouter(roleRouterItems)
{
    let routerItems = [{
        path: '/',
        component: resolve => require(['../../page/user-converse/UserConverse.vue'], resolve),
        meta: { title: '自述文件' },
        children:[],
    }];
    let common = {
        path: '/page/dashboard',
        component: resolve => require(['../../page/user-converse/UserConverse.vue'], resolve),
        meta: { title: '系统首页' },
        index:'dashboard',
        name: '系统首页',
        icon: 'iconfont icon-fenlei '
    };
    roleRouterItems.push(common);

	routerItems[0].children = roleRouterItems;

	let item403 = {
        path: '*',
        redirect: '../403'
    };
	routerItems.push(item403);
	return routerItems;
}

// Vuex分成五个部分：
// 1.State：单一状态树  //可以理解成定义属性
// 2.Getters：状态获取    //可以理解成取值，也就是get方法
// 3.Mutations：触发同步事件    //同步的赋值
// 4.Actions：提交mutation，可以包含异步操作    //异步的赋值（但是其实就是在Mutations的基础上包装了一层）
// 5.Module：将vuex进行分模块
const permission = {
	 state: {
	    routers: routes,
	    addRouters: []
	},
	mutations:{
	    SET_ROUTERS: (state, routers) => {
	    	let RouterItems = hasPermission(routers);
	    	//console.log(RouterItems);

	    	//添加两个默认路由
	    	let roleRouterItems = addDefaultRouter(RouterItems);

	    	//动态添加的路由
	    	state.addRouters = roleRouterItems
	      	//所有路由
			routes.options.routes.concat(roleRouterItems);
	      	state.routers = routes;
	      	 // console.log(state.addRouters)
	      	 // console.log(state.routers)
	    }

	},
	actions: {
		GenerateRoutes({ commit }, data) {
			//console.log(data);
			commit('SET_ROUTERS', data);
		},
	},

}

export default permission;
