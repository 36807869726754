

// Vuex分成五个部分：
// 1.State：单一状态树  //可以理解成定义属性
// 2.Getters：状态获取    //可以理解成取值，也就是get方法
// 3.Mutations：触发同步事件    //同步的赋值
// 4.Actions：提交mutation，可以包含异步操作    //异步的赋值（但是其实就是在Mutations的基础上包装了一层）
// 5.Module：将vuex进行分模块
const converse = {
	 state: {
        history:{},// 以账号id为key
         pages:{},// 以账号id为key
	},
	mutations:{
        SET_HISTORY: (state, data) => {
            //动态数据库信息
            state.history[data.key] = data.content;
        },
        SET_PAGES: (state, data) => {
            //动态数据库信息
            state.pages[data.key] = data.content;
        }
	},
	actions: {
        SetHistory({ commit }, data) {
            commit('SET_HISTORY', data);
        },
        SetPages({ commit }, data) {
            commit('SET_PAGES', data);
        },
	},

}
//store.dispatch('SetValidity', validityKey,token).then(() => {
//     store.getters.validityKey,
//     store.getters.validityEnd,// 授权最后有效日期
//     store.getters.isValidity,// 当前是否有效授权
//  })
export default converse;
