const getters = {
  routers: state => state.permission.routers,
  addRouters: state => state.permission.addRouters,
  validityKey: state => state.base.validityKey,
  validityEnd: state => state.base.validityEnd,
  isValidity: state => state.base.isValidity,
  db: state => state.base.db,
  nowUser: state => state.base.nowUser,
  isPhoneVisitor: state => state.base.isPhoneVisitor,
  converseHistory: state => state.converse.history,
  conversePages: state => state.converse.pages,
}
export default getters
// store.getters.isValidity;
