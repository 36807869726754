import { toRefs } from 'vue'


const customTools = {
    /**
     * 更新目标对象数据
     * @param targetData 目标对象
     * @param sourceData 数据对象（reactive）
     */
    updateByReactiveData(targetData: Object, sourceData: object){
        // 将state转换为ref形式的对象
        const refCustomGlobal = toRefs(sourceData);
        for (let key in refCustomGlobal) {
            targetData[key] = refCustomGlobal[key].value;
        }
        return targetData;
    }
}

export default customTools

