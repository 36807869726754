
const appImgDir = import.meta.env.VITE_IMG_DIR;

const dataDefaultHelper = {
    dashboard(){
        return {
            appKey: 'user-converse',//鉴权token的盐值token
            saltToken: 123,//鉴权token的盐值token
            UserDefaultName: 'zhangsan1',
            UserDefaultPic: appImgDir+'/default-user.png',
            CustomerServicePic: appImgDir+'/customer-service.jpeg',
        }
    }
};
export default dataDefaultHelper;