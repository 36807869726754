import dataHelper from './data-helper';
const localStorageUserKey = 'mi-user-info';// localstorage中账号信息的key
import store from '@app/store';

const systemHelper = {
    isSuperMan() { //是否为站长
        let userInfo = this.getUserObjectByStorage();
        let resultData = false;
        if(dataHelper.isObject(userInfo)){
            resultData = userInfo.username=='admin';
        }
        return resultData;
    },
    isVisitor() { //是否为游客
        let userInfo = this.getUserObjectByStorage();
        let resultData = false;
        if(dataHelper.isObject(userInfo)){
            resultData = userInfo.username=='visitor'
        }
        return resultData;
    },
    isHelperMan(username='') { //是否为助手
        return username == 'helper';
    },
    getAvatar() { //获取当前用户头像
        let userInfo = this.getUserObjectByStorage();
        let resultData = '';
        if(dataHelper.isObject(userInfo)){
            resultData = userInfo.avatar;
        }
        return resultData;
    },
    getNowUsername() { //获取当前用户账号名
        let userInfo = this.getUserObjectByStorage();
        let resultData = '';
        if(dataHelper.isObject(userInfo)){
            resultData = userInfo.username;
        }
        return resultData;
    },
    getShowUsername() { //获取当前用户可展示的名称
        let userInfo = this.getUserObjectByStorage();
        let resultData = '';
        if(dataHelper.isObject(userInfo)){
            resultData = dataHelper.isEmptyString(userInfo.nickname)?userInfo.username:userInfo.nickname;
        }
        return resultData;
    },
    // getAuthKey(){//获取鉴权秘钥
    //     return localStorage.getItem('key')?localStorage.getItem('key'):''
    // },
    getNowUserId(){
        let userInfo = this.getUserObjectByStorage();
        let resultData = 0;
        if(dataHelper.isObject(userInfo)){
            resultData = userInfo.id;
        }
        return resultData;
    },
    initStoreData(){ // 初始化公共会话
        let storeUser = store.getters.nowUser;
        if(dataHelper.isEmptyObject(storeUser)){
            let localstoreUserInfo = dataHelper.jsonStringToObject(localStorage.getItem(localStorageUserKey));
            if(!dataHelper.isEmptyObject(localstoreUserInfo)){
                store.dispatch('SetNowUser',localstoreUserInfo);// 设置公共回话账号信息
            }
        }
    },
    updateUserByKey(keyName='',KeyValue=''){
        let userInfo = dataHelper.jsonStringToObject(localStorage.getItem(localStorageUserKey));
        userInfo[keyName] = KeyValue;
        let newUserInfo = dataHelper.objectToString(userInfo);
        store.dispatch('SetNowUser',newUserInfo);// 设置公共回话账号信息
        return localStorage.setItem(localStorageUserKey,newUserInfo);// 设置浏览器localstore内容
    },
    getUserObjectByStorage(){
        let userInfo = store.getters.nowUser; // 优先获取store
        if(dataHelper.isEmptyObject(userInfo)){ // 获取浏览器localstore
            userInfo = dataHelper.jsonStringToObject(localStorage.getItem(localStorageUserKey));
        }
        if(dataHelper.isObjectString(userInfo)){
            userInfo = dataHelper.jsonStringToObject(userInfo);
        }
        return userInfo;
    },
    checkValidityKeyEndTime(EndTime=''){//检查鉴权日期是否过期
        let nowDay = dataHelper.getNowTimeStamp();// 单位s
        let endDate = new Date(EndTime);
        let endTimeStamp = endDate.getTime()/1000 ;// 单位s
        return nowDay >= endTimeStamp?false:true;
    },
    isPhoneVisitor(){
        let userAgentInfo = navigator.userAgent;
        let Agents = ["Android", "iPhone",
            "SymbianOS", "Windows Phone",
            "iPad", "iPod"];
        let isPhoneVisitor = false;
        for (let v = 0; v < Agents.length; v++) {
            if (userAgentInfo.indexOf(Agents[v]) > 0) {
                isPhoneVisitor = true;
                break;
            }
        }
        //var parentWidth = parent.document.body.clientWidth;
        let windowsinnerWidth = window.innerWidth;
        if(windowsinnerWidth<768){
            isPhoneVisitor = true;
        }
        if(windowsinnerWidth>768){
            isPhoneVisitor = false;
        }
        return isPhoneVisitor;
    },
};

export default systemHelper;